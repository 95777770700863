<template>
  <div class="rapairs-nav">
    <div id="linkbar" class="nav nav-pills">
      <router-link
        to="/repairs/add-new-repair"
        class="nav-link"
        active-class="active"
        >добавить ремонт</router-link
      >
      <router-link
        to="/repairs/repairs-list"
        class="nav-link"
        data-text="созданные ремонты"
        active-class="active"
        >созданные ремонты</router-link
      >
      <router-link
        to="/repairs/acts/acts-list"
        class="nav-link"
        data-text="отказы от ремонта"
        active-class="active"
        >отказы от ремонта
        <span class="badge badge-light">{{ ACTS_NO_CONFIRM_COUNT }}</span>
      </router-link>
      <router-link
        to="/repairs/diagnostics/diagnostics-list"
        class="nav-link"
        data-text="ремонты без зч и диагностики"
        active-class="active"
        >ремонты без зч и диагностики
        <span class="badge badge-light">{{ DIAGNOSTICS_COUNT }}</span>
      </router-link>
      <router-link
        to="/repairs/repairs-pending-issue-list"
        class="nav-link"
        data-text="ремонты ожидающие выдачи"
        active-class="active"
        >ремонты ожидающие выдачи
        <span class="badge badge-warning">{{
          REPAIRS_PENDING_ISSUE_COUNT
        }}</span>
      </router-link>
      <router-link
        to="/repairs/repairs-list-all"
        class="nav-link"
        data-text="check"
        active-class="active"
        >все ремонты</router-link
      >
      <router-link
        v-if="USER_INFO.status === 'admin'"
        to="/repairs/favorites"
        class="nav-link"
        data-text="check"
        active-class="active"
        >избранное
        <span v-if="FAVORITES_REPAIRS_COUNT > 0" class="badge badge-warning">{{
          FAVORITES_REPAIRS_COUNT
        }}</span></router-link
      >

      <router-link
        v-if="USER_INFO.status === 'admin'"
        to="/repairs/consultation"
        class="nav-link"
        data-text="check"
        active-class="active"
        >консультация с мастерской
        <span class="badge badge-warning">{{
          CONSULTATION_REPAIRS_COUNT
        }}</span>
      </router-link>
    </div>
    <div class="content" id="content">
      <router-view class="view" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Repairs",
  computed: {
    ...mapGetters([
      "PATH",
      "DIAGNOSTICS_COUNT",
      "ACTS_NO_CONFIRM_COUNT",
      "REPAIRS_PENDING_ISSUE_COUNT",
      "USER_INFO",
      "FAVORITES_REPAIRS_COUNT",
      "CONSULTATION_REPAIRS_COUNT"
    ])
  },
  mounted() {
    //this.contentSize();
  },
  methods: {
    ...mapMutations(["set_repairs_list_scroll"])
    //  contentSize() {
    //    const heigthNavBar = document.getElementById("navbar").offsetHeight;
    //    const heigthLinkBar = document.getElementById("linkbar").offsetHeight;
    //    const height = window.innerHeight;
    //    document.getElementById("content").style.height =
    //      height - heigthNavBar - heigthLinkBar - 10 + "px";
    //  }
  }
};
</script>
<style lang="scss" scoped>
.link {
  padding: 5px;
}
.rapairs-nav {
  padding: 5px;
}
.active {
  color: #fff;
  background: #007bff;
  cursor: default;
  text-decoration: none;
}
</style>
